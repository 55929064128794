<template>
    <div v-if="steps" class="flex flex-row items-center relative">
        <div v-for="(item, index) in steps" :key="index" :class="['flex items-center', index === 0 ? '' : 'grow']">
            <!-- Bar with dynamic width -->
            <div
                :class="[
                    'h-[8px] rounded z-10',
                    index + 1 <= step ? 'bg-brand opacity-70' : 'bg-gray-500',
                    index === 0 ? 'w-[50px] -mr-[14px]' : 'grow -mx-[14px]',
                ]"
            ></div>

            <!-- Icon and name for each step -->
            <div class="flex flex-col items-center z-20">
                <div
                    :class="[
                        'flex items-center justify-center rounded-full shadow-progress',
                        index + 1 <= step ? 'bg-brand w-10 h-10' : 'bg-gray-300 w-6 h-6',
                    ]"
                >
                    <font-awesome-icon v-if="index + 1 <= step" :icon="item.icon" class="text-md" />
                </div>

                <div
                    :class="[
                        'absolute bottom-[-25px] text-center text-md mt-1',
                        index + 1 <= step ? 'text-white' : 'text-gray-400',
                    ]"
                >
                    {{ item.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';

    import { PROGRESS_STEPS, ProgressBarTypes, Step } from '@/consts/progress';

    const steps = ref<Step[] | null>(null);

    const { config, step } = defineProps({
        config: {
            type: String as () => ProgressBarTypes,
            required: true,
        },
        step: {
            type: Number,
            required: true,
        },
    });

    onMounted(() => {
        steps.value = PROGRESS_STEPS[config];
    });
</script>
