<template>
    <aside class="md:max-w-sm">
        <div
            class="flex items-center w-full rounded-full py-[6px] px-3 bg-gray-700 hover:bg-gray-600 cursor-pointer transition-all duration-500"
            @click.prevent="open"
        >
            <font-awesome-icon class="text-xl text-white" icon="fa-light fa-magnifying-glass" />

            <p class="hidden sm:block mx-4 tracking-tight text-gray-200" v-text="t('Tracks, albums or artists...')" />

            <p class="sm:hidden mx-4 tracking-tight text-gray-200" v-text="$t('Search...')" />
        </div>

        <SearchModal @close="close" />
    </aside>
</template>

<script setup lang="ts">
    import { useStore } from 'vuex';

    import i18n from '@/i18n';

    import SearchModal from '@/components/global/search/SearchModal.vue';

    const { t } = i18n.global;

    const store = useStore();

    const open = () => {
        store.commit('search/SHOW_SEARCH');
        window.emitter.emit('close-user-modal');
    };

    const close = () => {
        store.commit('search/HIDE_SEARCH');
        window.emitter.emit('stop-search-audio');
    };
</script>
