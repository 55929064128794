<template>
    <div v-if="cartHasLimitedEditionItem" class="p-3 bg-orange-600 text-center text-sm">
        {{ t('Limited edition in your basket. You have five minutes to complete your purchase') }}
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useStore } from 'vuex';

    import i18n from '@/i18n';

    const { t } = i18n.global;

    const store = useStore();

    const cartHasLimitedEditionItem = computed(() => store.getters['cart/hasLimitedEditionItem']);
</script>
