import { GA_MEASUREMENT_ID_PROD, GA_MEASUREMENT_ID_STAGING } from '@/consts/ga';
import { USERCENTRICS_GA_SERVICE_ID, USERCENTRICS_SCRIPT_SETTINGS_ID } from '@/consts/usercentrics';
import Usercentrics from '@usercentrics/cmp-browser-sdk';

import { GtagEventQueueManager } from '@/classes/AnalyticsEventManager';

interface GtagConfig {
    config: { id: string };
    bootstrap: boolean;
    enabled: boolean;
}

const gtagEventManagerInstance = GtagEventQueueManager.getInstance();

export const event = gtagEventManagerInstance.event.bind(gtagEventManagerInstance);

export async function getGtagConfig(): Promise<GtagConfig> {
    const UC = new Usercentrics(USERCENTRICS_SCRIPT_SETTINGS_ID);

    await UC.init();

    return new Promise((resolve) => {
        const checkConsent = () => {
            return new Promise<boolean>((resolveConsent) => {
                const servicesBaseInfo = UC.getServicesBaseInfo();
                const service = servicesBaseInfo.find((service: any) => service.id === USERCENTRICS_GA_SERVICE_ID);
                resolveConsent(service?.consent.status ?? false);
            });
        };

        checkConsent().then((hasConsent) => {
            const config: GtagConfig = {
                config: { id: window.isProduction ? GA_MEASUREMENT_ID_PROD : GA_MEASUREMENT_ID_STAGING },
                enabled: hasConsent,
                bootstrap: hasConsent,
            };

            resolve(config);
        });
    });
}
