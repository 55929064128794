import algoliasearch from 'algoliasearch';
import mitt from 'mitt';

import 'vue-toastification/dist/index.css';

// Okay for now - remove objects from global window in future where possible
window.algoliasearch = algoliasearch;
window.algoliaClient = algoliasearch(import.meta.env.VITE_ALGOLIA_APP_ID, import.meta.env.VITE_ALGOLIA_SEARCH_KEY);
window.stripe = import.meta.env.VITE_STRIPE_KEY;
window.emitter = mitt();
