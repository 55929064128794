<template>
    <a
        :href="props.hit.url"
        class="flex items-center py-1 px-2 cursor-pointer hover:no-underline hover:bg-white hover:bg-opacity-10 hover:text-white"
    >
        <div class="flex justify-center flex-none text-xl w-20 mr-4">
            <div
                class="rounded-full h-12 w-12 bg-contain"
                style="box-shadow: rgb(255 255 255 / 40%) 0 0 10px"
                :style="{ backgroundImage: `url(${props.hit.thumb})` }"
            />
        </div>

        <p class="flex-1 text-xl truncate leading-tight">{{ props.hit.name }}</p>
    </a>
</template>

<script setup lang="ts">
    import { AisHit } from 'vue-instantsearch/vue3/es';

    const props = defineProps({
        hit: {
            required: true,
            type: Object as () => typeof AisHit,
        },
    });
</script>
