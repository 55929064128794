// Phase out this method
export const applyTestData = (id) => {
    return id;
};

export const bindTestData = (id) => {
    return {
        'data-test': id,
        'data-testid': id,
    };
};

export const getTestData = (path) => {
    return `[data-test="${path}"], [data-testid="${path}"]`;
};

export const composeTemplate = (componentName, args, classes) => {
    const styles = [];

    if (classes.includes('padding')) {
        styles.push('padding: 20px 100px;');
    }

    if (classes.includes('centered')) {
        styles.push('display: flex; justify-content: center; align-items: center; height: 100vh;');
    }

    return `<div style="${styles.join(' ')}"><${componentName} v-bind="args" /></div>`;
};
