import Echo from 'laravel-echo';

export default (() => {
    if (import.meta.env.VITE_BROADCAST_DRIVER === 'soketi') {
        return new Echo({
            broadcaster: 'pusher',
            key: import.meta.env.VITE_PUSHER_APP_KEY,
            wsHost: import.meta.env.VITE_PUSHER_HOST,
            forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
            encrypted: true,
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
        });
    }

    return new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
        forceTLS: true,
    });
})();
