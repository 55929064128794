export enum ProgressBarTypes {
    R2V_SIGNUP = 'R2V_SIGNUP',
}

export interface Step {
    name: string;
    icon: string;
}

type ProgressSteps = {
    [K in ProgressBarTypes]: Step[];
};

export const PROGRESS_STEPS: ProgressSteps = {
    [ProgressBarTypes.R2V_SIGNUP]: [
        { name: 'Album Details', icon: 'fa-solid fa-bars' },
        { name: 'Artwork', icon: 'fa-solid fa-image' },
        { name: 'Tracks', icon: 'fa-solid fa-list-music' },
    ],
};
