<template>
    <div>
        <div class="space-y-4">
            <ais-hits>
                <template #default="{ items }">
                    <ReleaseGroupResult v-for="item in items" :key="item.id" :hit="item" />
                    <p v-show="!items.length" class="text-lg text-gray-400 p-4" v-text="t('No albums found')" />
                </template>
            </ais-hits>

            <ais-index :search-client="searchClient" :index-name="'pages'">
                <ais-configure
                    :hits-per-page.camel="2"
                    :filters.camel="`(releases.territories.include: ${shippingCountry} OR releases.territories.include: World) AND NOT releases.territories.exclude:  ${shippingCountry}`"
                />

                <ais-hits>
                    <template #default="{ items }">
                        <ArtistResult v-for="item in items" :key="item.id" :hit="item" />

                        <p v-show="!items.length" class="text-lg text-gray-400 p-4" v-text="t('No artists found')" />
                    </template>
                </ais-hits>
            </ais-index>

            <ais-index :search-client="searchClient" :index-name="'tracks'">
                <ais-configure
                    :hits-per-page.camel="4"
                    :filters.camel="`(release.territories.include:  ${shippingCountry} OR release.territories.include: World) AND NOT release.territories.exclude:  ${shippingCountry}`"
                />

                <ais-hits>
                    <template #default="{ items }">
                        <SongResult
                            v-for="item in items"
                            ref="song"
                            :key="item.id"
                            :hit="item"
                            :src="currentSrc"
                            :is-playing="isPlaying"
                            @toggleAudio="toggleAudio"
                        />

                        <p v-show="!items.length" class="text-lg text-gray-400 p-4" v-text="t('No tracks found')" />
                    </template>
                </ais-hits>
            </ais-index>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, onMounted, ref } from 'vue';
    import { AisConfigure, AisHits, AisIndex } from 'vue-instantsearch/vue3/es';
    import { useStore } from 'vuex';

    import i18n from '@/i18n';

    import ArtistResult from '@/components/global/search/ArtistResult.vue';
    import ReleaseGroupResult from '@/components/global/search/ReleaseGroupResult.vue';
    import SongResult from '@/components/global/search/SongResult.vue';

    const { t } = i18n.global;

    const { searchClient } = defineProps({
        searchClient: {
            required: true,
            type: Object,
        },
    });

    const store = useStore();

    const shippingCountry = computed(() => store.getters['locales/shippingCountry']);

    const audio = ref<HTMLAudioElement>(new Audio());
    const currentSrc = ref<string>('');
    const isPlaying = ref<boolean>(false);

    onMounted(() => {
        audio.value.crossOrigin = 'anonymous';

        audio.value.onpause = () => {
            isPlaying.value = false;
        };

        audio.value.onplay = () => {
            isPlaying.value = true;
        };

        audio.value.onended = () => {
            isPlaying.value = false;
        };

        window.emitter.on('stop-search-audio', () => {
            audio.value.pause();
        });
    });

    const toggleAudio: (src: string) => void = (src: string) => {
        if (src !== currentSrc.value) {
            if (isPlaying.value) audio.value.pause();

            currentSrc.value = audio.value.src = src;

            window.emitter.emit('stop-audio');

            store.dispatch('media/stopPlayback');

            audio.value.play();
        } else {
            if (isPlaying.value) {
                audio.value.pause();
            } else {
                audio.value.currentTime = 0;
                audio.value.play();
            }
        }
    };
</script>
