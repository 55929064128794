type GtagEvent = {
    event: string;
    action: string;
    parameters: any;
};

export class GtagEventQueueManager {
    private static instance: GtagEventQueueManager;
    private eventQueue: GtagEvent[] = [];
    private isGtagAvailable: boolean = false;

    private constructor() {
        this.checkGtagAvailability();
    }

    public static getInstance(): GtagEventQueueManager {
        if (!GtagEventQueueManager.instance) {
            GtagEventQueueManager.instance = new GtagEventQueueManager();
        }

        return GtagEventQueueManager.instance;
    }

    private checkGtagAvailability(): void {
        const checkAvailability = () => {
            if (typeof window.gtag === 'function') {
                this.isGtagAvailable = true;
                console.log('gtag is now available. Processing queued events...');
                this.processEventQueue();
            } else {
                setTimeout(checkAvailability, 500); // Check every 500ms
            }
        };

        checkAvailability();
    }

    public event(event: string, action: string, parameters: any): void {
        if (this.isGtagAvailable) {
            window.gtag(event, action, parameters);
        } else {
            console.log(`gtag is not available yet. Queuing event: ${action}`);
            this.eventQueue.push({ event, action, parameters });
        }
    }

    private processEventQueue(): void {
        while (this.eventQueue.length > 0 && this.isGtagAvailable) {
            const { event, action, parameters } = this.eventQueue.shift()!;
            window.gtag(event, action, parameters);
        }

        console.log('All queued events have been processed.');
    }
}
