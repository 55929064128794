<template>
    <div class="flex items-center justify-center text-xs space-x-4 pt-4">
        <input
            id="confirmCheckbox"
            v-model="model"
            type="checkbox"
            class="fi-checkbox-input w-6 h-6 rounded border border-gray-300 bg-transparent shadow-sm ring-1 ring-gray-300 transition duration-150 focus:ring-2 focus:ring-orange-500 focus:bg-orange-500 checked:bg-orange-500 checked:ring-orange-500 checked:focus:bg-orange-500 checked:focus:ring-orange-500"
            :class="{ '!border-red-500': error, '!ring-red-500': error }"
        />
        <label for="confirmCheckbox" class="text-gray-300 mt-1" :class="{ error: error }">
            <slot />
        </label>
    </div>
</template>

<script setup lang="ts">
    import { defineModel } from 'vue';

    const { error } = defineProps({
        error: {
            type: Boolean,
            required: false,
            default: false,
        },
    });

    const model = defineModel<boolean>({ required: true });
</script>
