<template>
    <a
        :href="props.hit.url"
        class="flex items-center py-1 px-2 cursor-pointer hover:no-underline hover:bg-white hover:bg-opacity-10 hover:text-white"
    >
        <div
            class="flex-none w-20 h-20 bg-[percentage:100%_100%] mr-4"
            :style="{ backgroundImage: `url(${props.hit.thumb})` }"
        />

        <div class="flex-1 truncate leading-tight">
            <p class="text-xl truncate">{{ props.hit.title }}</p>
            <div class="text-gray-200 truncate">
                <span
                    v-if="false"
                    :title="t('Explicit')"
                    class="mt-[1px] mr-2 inline-flex justify-center items-center rounded-sm text-xs text-black bg-gray-200 px-1 py-2 h-[14px] align-top"
                    >E</span
                ><span v-for="(artist, index) in artists" :key="index"
                    >{{ artist }}{{ isLast(index) ? '' : ', ' }}</span
                >
            </div>
            <p class="mt-1 text-gray-400">
                <span>{{ props.hit.releases.length ? props.hit.releases[0].format : '' }}</span
                ><span class="mx-1">•</span
                ><span>{{
                    props.hit.releases.length ? new Date(props.hit.original_release_date).getFullYear() : ''
                }}</span>
            </p>
        </div>
    </a>
</template>

<script setup lang="ts">
    import { computed, ComputedRef } from 'vue';
    import { AisHit } from 'vue-instantsearch/vue3/es';

    import i18n from '@/i18n';

    const { t } = i18n.global;

    const props = defineProps({
        hit: {
            required: true,
            type: Object as () => typeof AisHit,
        },
    });

    const isLast: (index: number) => boolean = (index: number) => {
        return artists.value.length === index + 1;
    };

    const artists: ComputedRef<any[]> = computed(() => {
        if (props.hit.releases.length) {
            return [
                props.hit.releases[0].artist,
                ...props.hit.releases[0].additional_artists.map((artist: { name: string }) => artist.name),
            ];
        } else {
            return [];
        }
    });
</script>
