<template>
    <span ref="trigger" />
</template>

<script setup lang="ts">
    import { onMounted, onUnmounted, ref, watch } from 'vue';

    const props = defineProps<{
        options?: {
            root: Element | null;
            rootMargin: string;
            threshold: number | number[];
        };
    }>();

    const trigger = ref<HTMLElement | null>(null);

    const observer = ref<IntersectionObserver | null>(null);

    const emit = defineEmits(['triggerIntersected']);

    onMounted(() => {
        observer.value = new IntersectionObserver((entries) => {
            handleIntersect(entries[0]);
        }, props.options);

        if (trigger.value) {
            observer.value.observe(trigger.value);
        }
    });

    onUnmounted(() => {
        if (observer.value) {
            observer.value.disconnect();
        }
    });

    const handleIntersect = (entry: IntersectionObserverEntry) => {
        emit('triggerIntersected', entry);
    };

    watch(
        () => props.options,
        (newOptions) => {
            if (observer.value && trigger.value) {
                observer.value.disconnect();
                observer.value = new IntersectionObserver((entries) => {
                    handleIntersect(entries[0]);
                }, newOptions);
                observer.value.observe(trigger.value);
            }
        },
        { deep: true },
    );
</script>
