<template>
    <div class="relative">
        <input
            :id="$props.fieldId"
            :name="$props.fieldName"
            class="input pr-10"
            :type="passwordFieldType"
            autocomplete="password"
            required
        />
        <font-awesome-icon
            v-if="passwordFieldType === 'password'"
            icon="fa-light fa-eye"
            class="cursor-pointer text-gray-200 absolute right-3 top-3"
            @click="togglePasswordVisibility"
        />
        <font-awesome-icon
            v-else
            icon="fa-light fa-eye-slash"
            class="cursor-pointer text-gray-200 absolute right-3 top-3"
            @click="togglePasswordVisibility"
        />
    </div>
</template>

<script setup lang="ts">
    import { ref, type Ref, useAttrs } from 'vue';

    const props = defineProps({
        fieldId: {
            type: String,
            default: "password"
        },
        fieldName: {
            type: String,
            default: "password"
        }
    });

    const passwordFieldType: Ref<string> = ref('password');

    const togglePasswordVisibility = () => {
        passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password';
    };
</script>
