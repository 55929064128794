<template>
    <div
        v-if="isCartVisible"
        class="fixed min-h-screen z-50 inset-0 overflow-y-auto scrollbar-none"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
    >
        <div class="fixed inset-0 bg-black/60 backdrop-blur-sm" aria-hidden="true" @click.stop="hideCart" />

        <div class="sm:mt-20" @click.stop>
            <div class="relative panel-large">
                <div
                    class="text-3xl text-orange-500 tracking-tight"
                    :class="{ 'mb-6': !containsPreorderItems, 'mb-4': containsPreorderItems }"
                >
                    {{ t('Shopping Basket') }}
                </div>

                <span
                    v-if="items && items.length > 0"
                    class="text-sm ml-2 mt-4 mb-2 md:mt-0 p-3 inline-block text-[#95b5db]"
                >
                    <font-awesome-icon class="mr-1" icon="fa-solid fa-circle-info" />

                    {{ t('Order now and we will dispatch by ') }}
                    {{ dispatchDate }}.
                    <span v-if="containsPreorderItems">{{
                        t(
                            'Your entire order, including any preorder items, will be shipped together when everything is ready. For earlier delivery on select items, please place a separate order.',
                        )
                    }}</span>
                </span>

                <div>
                    <font-awesome-icon
                        v-if="isBusy"
                        icon="fa-light fa-arrows-rotate"
                        spin
                        class="w-full mx-auto text-gray-500 text-6xl"
                    />

                    <template v-else>
                        <div class="mb-6">
                            <div v-if="items && items.length > 0" class="w-full">
                                <CartItem
                                    v-for="(item, key) in items"
                                    :key="item.uuid"
                                    :item="item"
                                    :index="key"
                                    :error="errorUuids.includes(item.uuid)"
                                />

                                <div v-if="errorMessage" class="text-red-500">
                                    {{ t(errorMessage) }}
                                </div>

                                <div
                                    class="text-2xl flex justify-end items-baseline space-x-2"
                                    v-bind="bindTestData('retail.cart.subtotal')"
                                >
                                    <span>{{ t('Subtotal:') }}</span>
                                    <span class="font-bold">{{ subTotalFormatted }}</span>
                                </div>

                                <div class="text-l flex justify-end items-baseline text-gray-200 text-sm">
                                    <span>{{ t(vatText ?? '') }}</span>
                                </div>

                                <div class="flex justify-end items-baseline text-gray-200 text-sm mt-4">
                                    <span>{{ t('Postage & Packaging will be calculated at checkout') }}</span>
                                </div>

                                <ConfirmCheckbox v-model="confirmChecked" :error="confirmError">
                                    {{ t(CONFIRMATION_TEXT) }}
                                </ConfirmCheckbox>
                            </div>

                            <div v-else>
                                <div class="mb-6" v-bind="bindTestData('retail.cart.emptyMessage')">
                                    {{ t('Your Shopping Basket is empty') }}
                                </div>
                            </div>
                        </div>

                        <footer
                            class="flex items-center space-y-reverse space-y-6 flex-col-reverse sm:space-y-0 sm:flex-row sm:space-x-6 justify-end"
                        >
                            <a
                                class="link link-indigo uppercase hover:no-underline"
                                v-bind="bindTestData('retail.cart.continueShopping')"
                                @click.prevent="hideCart"
                            >
                                {{ t('Continue Shopping') }}
                            </a>
                            <a
                                v-if="items.length > 0"
                                class="button button-green hover:no-underline hover:text-black"
                                @click.prevent="checkout"
                            >
                                {{ t('Proceed to Checkout') }}
                            </a>
                        </footer>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, onMounted, ref } from 'vue';
    import { useStore } from 'vuex';

    import { CONFIRMATION_TEXT } from '@/consts/cart';
    import { event } from '@/ga';
    import i18n from '@/i18n';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    import ConfirmCheckbox from '@/components/global/ConfirmCheckbox.vue';
    import CartItem from '@/components/retail/CartItem.vue';
    import CartService from '@/services/CartService';

    import { CartItem as ICartItem } from '@/types/cart';

    import { bindTestData } from '@/tests/utilities';

    const { t } = i18n.global;
    const store = useStore();

    const confirmChecked = ref<boolean>(false);
    const confirmError = ref<boolean>(false);
    const isCartVisible = computed<boolean>(() => store.getters['cart/display']);
    const items = computed<ICartItem[]>(() => store.getters['cart/items']);
    const subTotalFormatted = computed<string>(() => store.getters['cart/totalIncVatFormatted']);
    const vatText = computed<string>(() => store.getters['cart/vatText']);
    const subTotal = computed<number>(() => store.getters['cart/totalIncVat']);
    const containsPreorderItems = computed<boolean>(() => store.getters['cart/containsPreorders']);
    const dispatchDate = computed<string>(() => store.getters['cart/dispatchDate']);

    const isBusy = ref<boolean>(false);
    const errorUuids = ref<string[]>([]);
    const errorMessage = ref<string>('');

    const hideCart = () => {
        confirmError.value = false;
        store.dispatch('cart/showCart', false);
    };

    const checkout = async () => {
        if (isBusy.value === true) {
            return;
        }

        if (!confirmChecked.value) {
            confirmError.value = true;
            return;
        }

        errorUuids.value = [];

        isBusy.value = true;

        event('event', 'begin_checkout', {
            currency: 'GBP',
            value: subTotal.value / 100,
            items: items.value.map((item: ICartItem) => ({
                item_id: item.uuid,
                item_name: `${item.meta.artist} - ${item.meta.title}`,
                item_variant: item.meta.description,
                price: item.priceIncVat / item.quantity,
                quantity: item.quantity,
            })),
        });

        try {
            await CartService.checkout();
        } catch (e: any) {
            switch (e.response.status) {
                case 303:
                    event('event', 'begin_checkout', {
                        currency: 'GBP',
                        value: subTotal.value / 100,
                        items: items.value.map((item: ICartItem) => ({
                            item_id: item.uuid,
                            item_name: `${item.meta.artist} - ${item.meta.title}`,
                            item_variant: item.meta.description,
                            price: item.priceIncVat / item.quantity,
                            quantity: item.quantity,
                        })),
                    });

                    // Redirect to stripe checkout
                    window.location.href = e.response.data.checkout_url;
                    break;
                case 422:
                    errorMessage.value = e?.response?.data?.message ?? 'An error occurred';

                    errorUuids.value = e?.response?.data?.uuids ?? [];

                    break;
                default:
                    errorMessage.value = 'An error occurred';
            }
        }

        isBusy.value = false;
    };

    onMounted(() => {
        document.addEventListener('keydown', (e) => {
            if (isCartVisible.value && e.key === 'Escape') {
                hideCart();
            }
        });
    });
</script>
