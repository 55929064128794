<template>
    <div
        class="h-25 flex items-start p-2 leading-tight space-x-4 rounded"
        :class="{
            'border border-red-500': customError || error,
        }"
        v-bind="bindTestData('retail.cartItem.container')"
    >
        <div
            class="w-24 h-24 bg-center bg-cover"
            :style="{ backgroundImage: artwork }"
            v-bind="bindTestData('retail.cartItem.thumbnail')"
        />

        <div class="h-full flex-1 flex justify-between truncate items-center space-x-2">
            <div class="h-full flex-1 flex flex-col justify-between truncate">
                <p class="text-xl tracking-tight truncate">
                    <img class="vertical-center h-[25px] inline mr-2" :src="item.meta.release_icon" alt="" />

                    <span
                        :title="item.meta.title"
                        class="align-text-top"
                        v-bind="bindTestData('retail.cartItem.title')"
                    >
                        {{ item.meta.title }}
                    </span>
                </p>

                <p class="truncate text-lg text-orange-500" v-bind="bindTestData('retail.cartItem.artist')">
                    <span
                        v-if="item.meta.explicit"
                        :title="t('Explicit')"
                        class="mt-[4px] mr-2 inline-flex justify-center items-center rounded-sm text-xs text-black bg-gray-200 px-1 py-2 h-[14px] align-top"
                        v-bind="bindTestData('retail.cartItem.explicit')"
                    >
                        E
                    </span>

                    {{ item.meta.artist }}
                </p>

                <p class="flex items-center space-x-2 mt-1">
                    <span>{{ t('Qty:') }}</span>

                    <Quantity
                        v-model="quantity"
                        :min="0"
                        :max="quantityLimit"
                        :disabled="!loaded"
                        @update:modelValue="handleInput"
                        @maxReached="qtyLimitReached = true"
                    />

                    <span
                        :title="t('Remove item')"
                        class="text-sm text-gray-300 hover:text-gray-200 hover:underline"
                        v-bind="bindTestData('retail.cartItem.delete')"
                        @click.stop.prevent="removeItemFromCart(item.uuid)"
                    >
                        {{ t('Delete') }}
                    </span>
                </p>

                <p class="flex flex-col">
                    <span v-if="qtyLimitReached" class="text-sm text-red-500">
                        {{ t('Maximum order quantity reached') }}
                    </span>

                    <span v-if="customError" class="text-sm text-red-500">
                        {{ t(customError) }}
                    </span>
                </p>
            </div>

            <div class="text-xl font-bold" v-bind="bindTestData('retail.cartItem.price')">
                {{ item.priceIncVatFormatted }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { useStore } from 'vuex';

    import { event } from '@/ga';
    import i18n from '@/i18n';
    import _ from 'lodash';

    import Quantity from '@/components/global/forms/Quantity.vue';

    import { CartItem } from '@/types/cart';

    import { bindTestData } from '@/tests/utilities';

    const store = useStore();
    const { t } = i18n.global;

    const props = defineProps({
        item: {
            type: Object as () => CartItem,
            required: true,
        },
        error: {
            type: Boolean,
            default: false,
        },
    });

    const previousQuantity = ref<number>(props.item.quantity);
    const quantity = ref<number>(props.item.quantity);
    const qtyLimitReached = ref<boolean>(false);
    const customError = ref<string>('');
    const artwork = ref<string>(`url(${props.item.meta.artwork})`);

    const quantityLimit = computed(() => store.getters['user/orderLimit']);
    const loaded = computed(() => store.getters['cart/loaded']);

    const handleInput = _.debounce((value) => {
        qtyLimitReached.value = false;
        customError.value = '';

        if (value === 0) {
            removeItemFromCart(props.item.uuid);
            return;
        }

        updateQuantity(value);
    }, 250);

    const removeItemFromCart = (uuid: string) => {
        event('event', 'remove_from_cart', {
            currency: 'GBP',
            value: props.item.priceIncVat / 100,
            items: [
                {
                    item_id: props.item.uuid,
                    item_name: `${props.item.meta.artist} - ${props.item.meta.title}`,
                    item_variant: props.item.meta.description,
                    price: props.item.priceIncVat / 100,
                    quantity: props.item.quantity,
                },
            ],
        });

        store.dispatch('cart/remove', { itemUuid: uuid, itemType: props.item.type });
    };

    const updateQuantity = (newQuantity: number) => {
        store
            .dispatch('cart/update', { item: props.item, quantity: newQuantity })
            .then(() => {
                previousQuantity.value = quantity.value;
            })
            .catch((e) => {
                customError.value = e.response?.data?.message ?? 'There was an error updating the item';
                quantity.value = previousQuantity.value;
            });
    };
</script>
