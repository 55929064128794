<template>
    <div v-if="show" class="bg-white w-[100vw] z-40 py-4">
        <div class="flex flex-row content-center">
            <div class="pl-4 text-sm md:text-base text-gray-500 content-center cursor-pointer" @click="open">
                {{ t('Prices and availability are based on your shipping country. Click') }}

                <font-awesome-icon icon="fa-light fa-truck" />
                <font-awesome-icon icon="fa-light fa-arrow-right" />
                <Flag
                    :country="currentCountry?.iso"
                    :rounded="true"
                    background="/images/vendor/vue-country-flag/dist/flags.png"
                    class="flag-display-small"
                />

                {{ t('to update it if needed. Currently set to: ') }}
                {{ t(currentCountry?.name) }}
            </div>
            <div
                class="text-black mt-[-3px] sm:mt-0 content-start sm:content-center cursor-pointer ml-auto mr-1 sm:mr-4 pr-4"
                @click="hide"
            >
                <font-awesome-icon icon="fa-solid fa-xmark" />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { computed, ComputedRef, inject, onMounted, ref, Ref, watch } from 'vue';
    import { VueCookies } from 'vue-cookies';
    import Flag from 'vue-country-flag-next';
    import { useStore } from 'vuex';
    import i18n from '@/i18n';

    import { Country } from '@/types/locales';

    const $cookies = inject<VueCookies>('$cookies');

    const cookieName = 'seen-shipping-country-reminder-header';

    const store = useStore();
    const { t } = i18n.global;

    const countries: ComputedRef<Country[]> = computed(() => store.getters['config/shippingCountries']);
    const shippingCountry: ComputedRef<string> = computed(() => store.getters['locales/shippingCountry']);

    const show: Ref<boolean> = ref<boolean>(true);
    const currentCountry = ref<Country | null>(null);

    onMounted(() => {
        if ($cookies?.isKey(cookieName)) {
            show.value = false;
        }
    });

    watch(
        countries,
        async () => {
            currentCountry.value = countries.value.find((country) => country.iso === shippingCountry.value) || null;
        },
        { immediate: true },
    );

    const hide = () => {
        show.value = false;
        $cookies?.set(cookieName, true, 60 * 60 * 24 * 365); // Expires in one year
    };

    const open = () => {
        store.commit('locales/OPEN_MODAL');
    };
</script>

<style scoped>
    .flag-display-small {
        display: inline-block !important;
        transform: scale(0.3) translateY(15px);
        margin-top: -20px !important;
    }
</style>
