<template>
    <article
        class="flex items-center py-1 px-2 cursor-pointer group hover:bg-white hover:bg-opacity-10 hover:text-white"
        @click.stop="jumpToAlbum"
    >
        <div class="text-center flex-none text-xl w-20 mr-4 group" @click.stop="emit('toggleAudio', props.hit.preview)">
            <template v-if="isPlayingCurrentTrack">
                <img
                    width="20"
                    height="20"
                    class="inline-block sm:group-hover:hidden aspect-[14/14]"
                    alt="Play"
                    src="/es-pics/es-play.gif"
                />

                <font-awesome-icon
                    icon="fa-solid fa-circle-pause"
                    class="text-gray-200 text-4xl hidden sm:group-hover:inline-block"
                />
            </template>

            <template v-else>
                <font-awesome-icon icon="fa-solid fa-circle-play" class="text-gray-600 hover:text-gray-200 text-4xl" />
            </template>
        </div>

        <div class="flex-1 truncate leading-tight">
            <div class="flex items-center truncate text-xl">
                <p class="flex-1 truncate" :class="isCurrent(props.hit.preview) ? 'text-orange-500' : 'text-white'">
                    {{ props.hit.title }}
                </p>

                <p
                    class="hidden sm:block flex-none mx-2 text-gray-600 group-hover:text-gray-400 max-w-[140px] truncate"
                >
                    {{ props.hit.release.title }}
                </p>
            </div>
            <div class="flex items-center text-gray-200 truncate">
                <p class="flex-1">
                    <span
                        v-if="props.hit.explicit"
                        :title="t('Explicit')"
                        class="mt-[1px] mr-2 inline-flex justify-center items-center rounded-sm text-xs text-black bg-gray-200 px-1 py-2 h-[14px] align-top"
                    >
                        E
                    </span>

                    <span v-for="(artist, index) in artists" :key="index">
                        {{ artist }}{{ isLast(index) ? '' : ', ' }}
                    </span>
                </p>

                <p
                    class="hidden sm:block flex-none mx-2 text-gray-600 group-hover:text-gray-400 max-w-[140px] truncate"
                >
                    {{ props.hit.release.format }}
                </p>
            </div>
        </div>
    </article>
</template>

<script setup lang="ts">
    import { computed, ComputedRef } from 'vue';
    import { AisHit } from 'vue-instantsearch/vue3/es';

    import i18n from '@/i18n';

    const { t } = i18n.global;

    const props = defineProps({
        hit: {
            required: true,
            type: Object as () => typeof AisHit,
        },
        src: {
            required: true,
            type: String,
        },
        isPlaying: {
            required: true,
            type: Boolean,
        },
    });

    const emit = defineEmits(['toggleAudio']);

    const isPlayingCurrentTrack: ComputedRef<boolean> = computed(() => {
        return props.isPlaying && isCurrent(props.hit.preview);
    });

    const artists: ComputedRef<any[]> = computed(() => {
        return [props.hit.artist, ...props.hit.additional_artists.map((artist: { name: string }) => artist.name)];
    });

    const isLast: (index: number) => boolean = (index: number) => {
        return artists.value.length === index + 1;
    };

    const isCurrent: (url: string) => boolean = (url: string) => {
        return props.src === url;
    };

    const jumpToAlbum: () => void = () => {
        location.href = props.hit.release.url;
    };
</script>
